<template>
  <div class="flex flex-col gap-6">
    <TextField type="text" label="NPWP ID" placeholder="Employee's NPWP ID" :borderEnabled="true" v-model="email" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Name (based on NPWP)" placeholder="Employee's fullname" :borderEnabled="true" v-model="otherEmail" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Address (based on NPWP)" placeholder="Employee's address" :borderEnabled="true" v-model="email" :disabled="props2 === 'Detail'" />
    <TextField type="text" label="Domicile" placeholder="Employee's domicile" :borderEnabled="true" v-model="otherEmail" :disabled="props2 === 'Detail'" />
  </div>
</template>

<script>
export default {
  props: {
    props1: {
      type: Object,
      default: () => ({})
    },
    props2: {
      type: String
    }
  },
  computed: {
    email() {
      return this.props1.email || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.email : 'Data unavailable'
    },
    otherEmail() {
      return this.props1.otherEmail || this.props2 === 'Edit' || this.props2 === 'Create New' ? this.props1.otherEmail : 'Data unavailable'
    }
  },
  components: {
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  }
}
</script>

<style></style>
