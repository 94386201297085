var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "NPWP ID",
      "placeholder": "Employee's NPWP ID",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Name (based on NPWP)",
      "placeholder": "Employee's fullname",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.otherEmail,
      callback: function callback($$v) {
        _vm.otherEmail = $$v;
      },
      expression: "otherEmail"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Address (based on NPWP)",
      "placeholder": "Employee's address",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Domicile",
      "placeholder": "Employee's domicile",
      "borderEnabled": true,
      "disabled": _vm.props2 === 'Detail'
    },
    model: {
      value: _vm.otherEmail,
      callback: function callback($$v) {
        _vm.otherEmail = $$v;
      },
      expression: "otherEmail"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }